/* ========================================

    btn
        ボタンのスタイル

    INDEX
        - class
        - normal
        - special
        - cart

======================================== */

// ========================================
//  class
// ========================================
// 一番普通のボタンの共通スタイル
%normal-btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 15px 20px !important;
    text-align: center;
    font-weight: bold;
    border: 2px solid $C_MAIN !important;
    border-radius: 100px;
    color: $C_MAIN;
	background-color: $C_WHITE;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    transition: 0.5s;

    &:hover {
        position: relative;
        top: 2px;
        box-shadow: none;
        color: $C_MAIN;
        background-color: $C_WHITE;
        opacity: 1;

        .arrow {
            &-back, &-next {
                &:before, &:after {
                    background-color: $C_MAIN;
                }
            }
        }
    }
}

// 目立たせたいボタンの共通スタイル
%special-btn {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 15px 20px !important;
    text-align: center;
    font-weight: bold;
    border: 2px solid $C_MAIN !important;
    border-radius: 100px;
    color: $C_SUB2;
    background-color: $C_MAIN;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    transition: 0.5s;

    &:hover {
        position: relative;
        top: 2px;
        box-shadow: none;
        color: $C_SUB2;
        background-color: $C_MAIN;
        opacity: 1;

        .arrow {

            &-back,
            &-next {

                &:before,
                &:after {
                    background-color: $C_MAIN;
                }
            }
        }
    }
}

// 幅いっぱいのサイズ
%btn-100 {
    display: block;
    width: 100%;
    
    @include mq-pc {
        max-width: 480px !important;
    }
}

// ２つ並べられるサイズ
%btn-50 {
    display: inline-block;
    width: 49%;
}

// ちっちゃいサイズ
%btn-30 {
    display: block;
    width: 30%;
}


/* ========================================
    normal
        よく使うボタン
======================================== */
.btn-100 {
    @extend %normal-btn;
    @extend %btn-100;
}

.btn-50 {
    @extend %normal-btn;
    @extend %btn-50;
}

.btn-30 {
    $this: &;
    @extend %normal-btn;
    @extend %btn-30;
    margin: auto;

    // 右寄せ
    &--right {
        @extend #{$this};
        margin-right: 0;
        margin-left: auto;
    }

    // 左寄せ
    &--left {
        @extend #{$this};
        margin-right: auto;
        margin-left: 0;
    }
}


/* ========================================
    special
        特別目立たせたいボタン
======================================== */
.btn-special-100 {
    @extend %special-btn;
    @extend %btn-100;
}

.btn-special-50 {
    @extend %special-btn;
    @extend %btn-50;
}


/* ========================================
    - regist
======================================== */
input[type=button] {
    @extend %normal-btn;
    margin-right: auto;
    margin-left: 0;
}


/* ========================================
    - cart
======================================== */
.cart_button,
.fc_button {
    @extend %normal-btn;
    @extend %btn-100;
}

.cart_button_center,
.cart_button_center1,
.fc_button_center {
    text-align: center;

    & > div {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    &>div>.cart_button,
    &>div>.cart_button,
    &>div>.fc_button {
        float: unset !important;
    }
    
    @include mq-mb {
        // margin-right: 15px;
        // margin-left: 15px;
        
        & > div > .cart_button,
        & > div > .cart_button,
        & > div > .fc_button {
            width: 100%;
		    margin: 5px 0;
        }
    }
}

.cart_button_side {
    text-align: center;
    
    @include mq-mb {
        // margin-right: 15px;
        // margin-left: 15px;
        
        & > div > .cart_button {
            width: 100%;
        }
    }
}

/*共通ボタン*/
.btn {
    @extend %normal-btn;
    @extend %btn-100;
}