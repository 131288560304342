/* ========================================

    link
        リンクのパーツ

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.link_guide {}
.link_guide_list {}
a.link_def,
a.link_ec,
a.link_fc,
a.link_cust {
	margin: 3px 3px;
}

.link_guide {}
.link_guide_list {}
a.link_mypage {
	margin: 3px 15px;
	@extend %normal-btn;
	@extend %btn-100;
	text-decoration: none;

	@include mq-mb {
		margin: 0;
	}
}

/*リンク 標準*/
a.link_def {}

/*リンク EC*/
a.link_ec {}

/*リンク FC*/
a.link_fc {}

/*リンク FC継続手続*/
a.fccontinue_link {}

/*リンク FC新規入会*/
a.fcjoin_link {}

/*リンク FC登録(入会)*/
a.fcregister_link {}

/*リンク FC登録(紐付)*/
a.fcregister_link2 {
    margin-top: 16px;
}

/*リンク 会員*/
a.link_cust {}

/*注文支払変更リンク*/
/*注文取消リンク*/
/*注文変更リンク*/
a.order_paychange_button,
a.order_cancel_button,
a.order_change_button {
	display: block;
	float: none;
	margin: 10px 0 0 0;
}

/*会員新規入会リンク*/
a.fcjoin_link {
	display: block;
	float: left;
	/*padding: 10px;*/
	border-radius: 8px;
	-webkit-border-radius: 8px;
	/*background: #eef4fa;*/
}

/*FC会員継続手続きリンク*/
a.fccontinue_link {
	display: block;
	float: left;
	/*padding: 10px;*/
	border-radius: 8px;
	-webkit-border-radius: 8px;
	/*background: #eef4fa;*/
}
