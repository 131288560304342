/* ========================================

    footer
        フッターのパーツ

    INDEX
        - common

======================================== */

/* ========================================
    common
======================================== */
#footer {
    padding-top: 50px;
    width: 100%;
    background: url(/modd/images/footer_bg.png);
    background-size: cover;
    position: relative;
}
/*フッター中央寄せ*/
#common_footer {
    display: none;
    @include mq-pc() {
        text-align: center;
    }

    @include mq-mb() {
        margin-bottom: 20px;
    }

    ul > li {
        @include mq-pc() {
            display: inline-block;
        }

        @include mq-mb() {
            display: block;
        }

        a {
            display: block;
            padding: 12px;
            color: $C_WHITE;
            font-size: 1.4rem;
            font-weight: 700;
            text-decoration: unset;

            @include mq-pc() {
                text-decoration: unset;
            }
        }
    }
}

/* ========================================
    common
======================================== */

/* [カートフッター]---------- */
#cart_footer {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    color: $C_SUB1;
    text-align: center;
    font-size: 0.85rem;
}

.footer-logo {
    padding-top: 49px;
    text-align: center;
    width: 160px;
    margin: 0 auto;
}

.footer-of {
    margin: 48px auto 0;
    width: 295px;

    a {
        border: solid 1px white;
        font-size: 1.5rem;
        font-family: $ENG_FONT_FAMILY;
        font-weight: 500;
        color: white;
        text-align: center;
        display: block;
        padding: 15px 0;
        line-height: 1;
        text-decoration: none;
    }
}

.footer-sns {
    margin-top: 49px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        width: 40px;
        height: 40px;
        margin-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
            margin-right: 0;
        }

        a {
            display: block;
        }
    }
}

.footer-nav {
    display: flex;
    justify-content: space-between;
    max-width: 695px;
    width: 100%;
    margin: 39px auto 0;

    @include mq-mb {
        flex-wrap: wrap;
        max-width: 310px;
        text-align: left;
        letter-spacing: normal;
    }

    &__item {
        color: white;
        font-size: 1.2rem;
        font-weight: 700;
        text-decoration: none;

        @include mq-mb {
            width: 50%;
            margin-bottom: 20px;
        }

        &:hover {
            color: white;
        }
    }
}

.footer-copy {
    text-align: center;
    color: white;
    font-size: 1rem;
    font-family: $ENG_FONT_FAMILY;
    margin-top: 15px;
    padding-bottom: 30px;

    @include mq-mb {
        margin-top: 20px;
    }
}

.footer-text {
    font-size: 1.1rem;
    text-align: center;
    color: white;
    margin-top: 54px;

    @include mq-mb {
        max-width: 280px;
        text-align: left;
        margin: 54px auto 0;
    }
}