/* ========================================
    
    header
        ヘッダーのパーツ

    INDEX
        - logo
        - common

======================================== */

/* ========================================
    logo
======================================== */
.header-logo {
    max-width: 335px;
    @extend %h-opa;

    @include mq-mb() {
        width: 240px;
    }

    img {
        width: 100%;
    }
}

/* ========================================
    common
======================================== */
#header {
    position: relative;
    margin-bottom: 3.5rem;
    background-color: $C_WHITE;
    z-index: 100;

    @include mq-pc() {
        height: $HEADER_H + 20;
    }
}

#common_header {

    @include mq-pc() {
        @include center;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
    }

    @include mq-mb() {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: $HEADER_H;
        background-color: $C_SUB2;
    }

    #cart_logo {
        position: absolute;
        display: block;
        
        @include mq-pc() {
            top: 0px;
            left: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
        }

        @include mq-mb() {
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
        }
    }
    
    .navbar {
        /*ヘッダー寄せなし*/
        @include mq-pc() {
            float: none;
            border: none;
            border-radius: 0;
            margin: 0;
            padding: 0;
            right: 0;
            width: 60%;
        }

        @include mq-mb() {
            position: absolute;
            top: 0;
            right: 0;
        }

        &-toggle {
            background-color: $C_SUB2;
            margin: auto;
            width: $HEADER_H;
            height: $HEADER_H;
            border: none;
            border-radius: 0;
            position: relative;
            z-index: 999;

            &::after {
                @include mq-mb {
                    content: "";
                    background: $C_ORANGE;
                    border-radius: 50%;
                    width: 86px;
                    height: 86px;
                    position: absolute;
                    top: -25px;
                    right: -30px;
                }
            }
    
            .icon-bar {
                position: absolute;
                top: 25px;
                right: 15px;
                margin: auto;
                height: 2px;
                width: 14px;
                background-color: $C_SUB2;
                transition: all 0.3s ease-out;
                transform-origin: center center;
                z-index: 1;
        
                &:first-child {
                    margin-top: -8px;
                }
        
                &:last-child {
                    margin-top: 8px;
                }
            }
        
            &:not(.collapsed) {
                background-color: transparent;
                .icon-bar {
                    background-color: $C_SUB2;
        
                    &:first-child {
                        transform: translate(-50%, -50%) rotate(45deg);
                        margin-top: 2px;
                    }
        
                    &:nth-child(2) {
                        opacity: 0;
                    }
        
                    &:last-child {
                        transform: translate(-50%, -50%) rotate(-45deg);
                        margin-top: 2px;
                    }
                }
            }
        }

        &-collapse {
            @include mq-mb() {
                position: fixed;
                background-color: $C_MAIN;
                top: 0;
                right: 0;
                width: 100%;
                height: 100% !important;
                transform: translate(100%);
                visibility: hidden;
                z-index: 100;
                transition: transform 0.3s;

                &.in {
                    visibility: visible;
                    transform: translate(0);
                }
            }
        }

        .nav {
            width: 100%;

            @include mq-pc() {
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
                border: 1px solid $C_SUB2;
                background-color: $C_SUB2;
                text-align: right;
            }

            @include mq-mb() {
                @include center();
                margin-top: -$HEADER_H;
                margin-left: 20px;
                width: 100%;
            }

            /*ヘッダーメニュー非表示*/
            & > #cmnheaditem_login_mypage,
            & > #cmnheaditem_logoff_mypage,
            & > #cmnheaditem_help_contact_list {
                display: none;
            }

            & > #cmnheaditem_help_contact_list {
                @include mq-mb() {
                    display:none;
                }
            }

            & > li {
                @include mq-pc() {
                    display: inline-block;
                    padding: 0 10px;
                
                    a {
                        display: block;
                        padding: 10px 0;
                        font-size: 1em;
                        font-weight: bold;
                        text-align: center;
                    }
                }

                a {
                    @extend %h-opa;

                    @include mq-pc() {
                        background-color: $C_SUB2;
                        color: $C_LINK_TEXT;
                        text-decoration: none;
                        text-align: center;
                    }

                    @include mq-mb() {
                        display: block;
                        padding: 20px 10px;
                        color: $C_WHITE;
                        text-decoration: unset;
                        font-size: 1.4rem;
                        font-weight: bold;
                        letter-spacing: 0.1em;
                    }
                }

                &:first-child a {
                    border-left: 0px;
                }

                &:last-child a {
                    border-right: 0px;
                }
            }
        }
    }
}

.common_header_menu {
    border: none;
    
    @include mq-pc() {
        width: 30%;
    }
}


/*ヘルプ　お問い合わせ統合メニュー*/
#cmnheaditem_help_contact_list {
    & > button {
        padding: 9px 10px;
        background-color: transparent;
        border: 1px solid transparent;
        vertical-align: middle;

        & > span {
            width:22px;
            height: 2px;
            display:block;
            background-color: $C_MAIN;

            & + span {
                margin-top: 4px;
            }
        }

        &:hover, &:focus {
            background-color: $C_SUB1;
        }
    }
}


/* [カートヘッダー]---------- */
#cart_header {
	display:none;
}