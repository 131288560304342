/* ========================================

    common
        共通に使えるパーツ

    INDEX
        - wrapper

======================================== */

body {
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    color: $C_BLACK;
}

:root body { display: block\0; }

#wrapper {
    word-wrap: break-word;
    flex-grow: 1;
    margin: 0 auto;
    width: 100%;
    box-shadow: 0 3px 26px rgba(0, 0, 0, 0.16);
    background: url(/modd/images/bg_orange.jpg);

    @include mq-mb() {
        padding-top: $HEADER_H + 10px;
        width: 100%;
    }
}

// MODDのDOM構成がおかしいためよくある質問のSPページだけフッターの左右に余白ができる
// もし対策したい場合は以下のコメントアウトを外す
// #wrapper > #footer {
//     @include mq-mb() {
//         width: 100vw;
//         margin-inline: calc(50% - 50vw);
//     }
// }

#contents {
    margin-bottom: 80px;
}

@include mq-pc() {
	#wrapper,
	#header,
	#footer {
        width: 100%;
		margin-right: 0;
		margin-left: 0;
		padding-right: 0;
		padding-left: 0;
	}
	#footer > .row {
		margin-right: 0;
		margin-left: 0;
	}
	#contents {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
	}
}


/* [共通ボディ]---------- */
#common_body {
	padding:0px 10px
}


/* [ダイアログ]---------- */
#dialog {
	margin: 0;
	padding: 10px;
}


/* [その他文字装飾]---------- */
.order_id_txt,
.preorder_id_txt {
	font-weight: bold;
}
/* その他微調整 */
#User_Agree_CheckBox {
	margin-top: 25px;
}
/*
.privacy_table .privacy_body #txtPrivacy,
.kiyaku_table .kiyaku_body #txtKiyaku {
	height: 100px;
}
*/


/* [input系]---------- */
input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 3px ;
    border: 1px solid $C_GRAY;
}

select {
    height: 40px;
}

input[type="text"],
select,
textarea {
    width: 100%;
}

.table_control select {
    max-width: max-content;
}
