/*∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴
    txt
∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴∵∴*/
#cmnhead_button_cart::after {
    content: "カートを見る";
}

#cmnhead_button_login::after {
    content: "ログイン";
}

#cmnhead_button_fchelp::after,
#cmnhead_button_help::after {
    content: "よくある質問";
}

#RegisterLink:after {
    content: "無料会員登録";
}

#cmnhead_button_mypage::after {
    content: "マイページ";
}

#cmnhead_button_logoff::after{
    content: "ログアウト";
}
