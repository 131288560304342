/* ========================================

    index
        フロー案内で使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.title_guide {
	padding: 0px;
}

.flow_guide {
    margin-top: 20px;

    @include mq-pc() {
        margin-bottom: 40px;
    }

    @include mq-mb() {
        margin-bottom: 20px;
    }

    ul {
        display: flex;
        list-style: none;
        width: 100%;
        padding: 0px;
        border-collapse:collapse;
    }

    li {
        color: $C_GRAY;
        font-size: 1.4rem;
        font-weight: 700;
        text-align: center;
        border-bottom: solid 2px $C_GRAY;
        padding-bottom: 28px;
        width: 20%;
        margin: 0 10px;

        &.current_action {
            color: $C_MAIN;
            border-bottom: solid 2px $C_MAIN;
        }
    }
}


/*購入フロー*/
#purchase_flow01 ul li,
#purchase_flow02 ul li,
#purchase_flow03 ul li,
#purchase_flow04 ul li,
#purchase_flow05 ul li {
	width: 24%;
	margin-right: 5px;
}


/*会員登録フロー*/
#register_flow01 ul li {
	width: 25%;
}


/*ファンクラブ新規入会フロー*/
#fcjoin_flow01 ul li,
#fcjoin_flow02 ul li {
	width: 24%;
	margin-right: 5px;
}


/*ファンクラブ新規入会(支払登録)フロー*/
#fcpay_flow01 ul li,
#fcpay_flow02 ul li,
#fcpay_flow03 ul li,
#fcpay_flow04 ul li {
	width: 20%;
}


/*仮予約フロー*/
#preorder_flow01 ul li,
#preorder_flow02 ul li {
	width: 33%;
}