/* ========================================

    index
        買い物カゴで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.basket_panel {
	margin-top: 0;
	margin-bottom: 15px;
    padding: 0;
    
    & > .basket_body {
        padding-bottom: 20px;

        @include mq-mb() {
            border-bottom: 1px solid #DDD;
        }

        &:last-child > div:last-child{
            border-bottom-style: none;
        }
    }
}

/*買い物カゴヘッダー*/
.basket_header {
	background-color: $C_MAIN;
	border-style: none none solid none;
	border-color: $C_MAIN;
	border-width: 1px;
	height: 40px;
    color: white;
}

/*買い物カゴヘッダー見出し*/
.basket_caption {
	text-align: left;
	padding-top: 8px;
}

/*買い物カゴヘッダータイトル*/
.basket_title {
	text-align: left;
	padding-top: 8px;
}

/*買い物カゴ行*/
.basket_body {
	padding-top: 10px;
}

/*買い物カゴ行上(商品)*/
.basket_body_upper {}

/*買い物カゴ行下(メッセージ)*/
.basket_body_lower {}

/*買い物カゴフッター*/
.basket_footer {
	background-color: transparent;
	height: 45px;
    padding-top: 8px;
    
    div {
        padding-top: 10px;
        font-weight: 600;

        &.basket_item_num {
            padding-top: 0;
            font-size: 24px;
        }
    }
}

.basket_footerlabel {}

/*買い物カゴ行項目*/
.basket_body_upper {
    @include mq-mb() {
        display: block;
    }
}

.bskitem_title {
    display: table;
    width: 100%;
}

.basket_item,
.basket_itemlabel {
	padding: 1px 15px 1px 15px;
	/*min-height: 40px;*/
}

.basket_item_amount {
	font-weight: bold;
}

.basket_item select {
    margin-right: 10px;
    
    @include mq-pc() {
        width: 50%;
    }
}

.bskbody_basketimg .bskitem_protitle,
.bskbody_basketimg .bskitem_price {
	font-size: 17px;
}

.bskitem_proimg {
    @include mq-pc() {
        width:120px;
    }

    @include mq-mb() {
        width:80px;
    }
}

.bskitem_proinfo {
    padding-left: 20px;
	vertical-align:middle;
}

/*買い物カゴ行メッセージ*/
.basket_body_errmsg {}

/*買い物カゴ連番(#NO)*/
.basket_caption_no,
.basket_item_no {
	text-align: right;
}

/*購入ボタン*/
#rejibtn_lnk.cart_button {
	padding: 15px 0 15px 0;
	color: $C_SUB1;
	background-color: $C_MAIN;
    border-color: $C_SUB1;

    @include mq-pc() {
        width: 240px;
    }
    
    &:hover {
        color: $C_MAIN;
        background-color: $C_SUB1;
        border-color: $C_SUB1 !important;
        opacity: 1;
    }
}

/*CheckOutConf 注文を確定する*/
#btnOrder_lnk {
	color: $C_MAIN;
	background-color: $C_SUB1;
    border-color: $C_SUB1;
    
    &:hover {
        background-color: $C_RED;
        border-color: $C_RED !important;
    }
}

/*fcpay 申込む*/
#fcRegisterBtn {
	color: #FFF;
	background-color: $C_MAIN;
    border-color: $C_MAIN;
}

/*PreOrder 申込み*/
#preOrderBtn {
	color: $C_MAIN;
	background-color: $C_SUB1;
    border-color: $C_SUB1;
    
    &:hover {
        background-color: $C_RED;
        border-color: $C_RED !important;
    }
}