/* ========================================
    
    status
        ステータスのパーツ

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
/* [その他共通]---------- */
/*必須項目*/
.col_must:before {
	content: "※";
	color:#f00;
	font-weight:normal;
}

/*注文状態の説明*/
/*在庫確認中/未入金/受取準備中（未入金）*/
.orderstatus1_txt {
	font-size: 1.4rem;
	color:#e822c5;
}
/*出荷待ち/受取準備中*/
.orderstatus2_txt {
	font-size: 1.4rem;
	color:#666666;
}
/*出荷準備中*/
.orderstatus3_txt {
	font-size: 1.4rem;
	color:#c98e00;
}
/*出荷済み/受取準備完了*/
.orderstatus4_txt {
	font-size: 1.4rem;
	color:#00a19b;
}
/*配達済み/受取完了*/
.orderstatus5_txt {
	font-size: 1.4rem;
	color:#513aff;
}
/*取消*/
.orderstatus6_txt {
	font-size: 1.4rem;
	color:#FF0000;
}
/*お支払完了*/
.orderstatus7_txt {
	font-size: 1.4rem;
	color:#007fd0;
}
/*決済完了*/
.orderstatus8_txt {
	font-size: 1.4rem;
	color:#513aff;
}
/*任意入力*/
.orderstatus9_txt {
	font-size: 1.4rem;
	color:#666666;
}

/*先行・仮予約状態の説明*/
/*予約受付済*/
.PreOrder_status0 {
	font-size: 1.4rem;
	color:#6af;
}
/*決済受付準備中*/
.PreOrder_status1 {
	font-size: 1.4rem;
	color:#6af;
}
/*決済受付中*/
.PreOrder_status2 {
	font-size: 1.4rem;
	color:#33f;
}
/*決済完了*/
.PreOrder_status3 {
	font-size: 1.4rem;
	color:#3c3;
}
/*期限切れキャンセル*/
.PreOrder_status6 {
	font-size: 1.4rem;
	color:#f33;
}
/*落選キャンセル*/
.PreOrder_status7 {
	font-size: 1.4rem;
	color:#f33;
}
/*予約キャンセル（ユーザーによる）*/
.PreOrder_status8 {
	font-size: 1.4rem;
	color:#f33;
}
/*予約キャンセル（管理者よる）*/
.PreOrder_status9 {
	font-size: 1.4rem;
	color:#f33;
}
/*未入金*/
.PreOrder_status-1 {
	font-size: 1.4rem;
	color:#e822c5;
}

/* ファンクラブ会員状態の説明 */
/*継続中*/
.fc_status0 {
    font-size: 1.4rem;
	color:#3af;
}
/*休眠状態*/
.fc_status1 {
    font-size: 1.4rem;
	color:#fa3;
}
/*退会済み*/
.fc_status8 {
    font-size: 1.4rem;
	color:#c33;
}
/*期限切れ*/
.fc_status9 {
    font-size: 1.4rem;
	color:#c33;
}
/*入会・継続申込み状態の説明*/
/*未入金*/
.fcpay_status0 {
    font-size: 1.4rem;
	color:#666666;
}
/*入金済*/
.fcpay_status1 {
    font-size: 1.4rem;
	color:#513aff;
}
.fcpay_status2 {
    font-size: 1.4rem;
	color:#513aff;
}
/*取消*/
.fcpay_status9 {
    font-size: 1.4rem;
	color:#ff0000;
}