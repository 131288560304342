// ========================================
//  aem scss templates
//      ver 2.0.3
// ======================================== 
@charset 'UTF-8';


/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
@import "common/variables";
@import "common/mixins";
@import "common/reset";
// @import "common/normalize";
@import "common/base";
@import "common/parts";
@import "common/animation";




/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */

// タブレット以上の場合のみホバーを適応
@media screen and (min-width: $BP_M) {
    @import "components/hover";
}

@import "components/common";
@import "components/header";
@import "components/footer";
@import "components/table";
@import "components/title";
@import "components/btn";

// MODD独自のもの
@import "components/flow";
@import "components/link";
@import "components/message";
@import "components/panel";
@import "components/txt";
@import "components/status";



/* ========================================
    pages
        ページごとに独立するスタイル
======================================== */
@import "pages/index";
@import "pages/login";
@import "pages/help";
@import "pages/basket";
