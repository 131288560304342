/* ========================================

    panel
        パネルのパーツ

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.panel{
    border-radius: 10px;
    border-color: $C_WHITE;
    border: none;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);

    .panel-body {
        padding: 20px;
    }
}

.help_panel > .panel {
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
}

.panel-default > .panel-heading {
    background-color: transparent;
    border-radius: 0;
    text-align: center;
}

.description_panel > .panel-heading {
	background-color: transparent;
}

.notcancel_text {
	margin-top: 10px;
	padding: 0px;
}

/*完了画面SUCCESS/ERRORメッセージ*/
.finmsg_panel {
	margin-top: 15px;
    text-align: center;
    
    & > .alert {
        padding: 30px;
    }
}

/*情報パネル*/
.info_panel {
	margin-top: 5px;
    padding: 0px;
    
    .panel-heading {
        color: $C_SUB2;
        background-color: $C_MAIN;
        border: none;
        border-radius: 10px 10px 0 0;
        font-weight: 700;
        font-size: 1.8rem;
    }
}

/*説明パネル(線なし)*/
.description_panel {
    margin-top: 15px;
    
    & > .panel-heading {
        padding: 3px;
    }

    & > .panel-body {
        padding: 2px 10px 10px 10px;
    }
}

/*注意パネル*/
.fc_register_table {
    .caution_panel {
        background-color: transparent;
        .panel-heading {
            font-size: 120%;
            font-weight: bold;
            text-align: center;
            color: $C_SUB2;
            background-color: $C_MAIN;
            border: 2px solid $C_MAIN;
            border-radius: 10px 10px 0 0;
        }

        .panel-body {
            border: 2px solid $C_MAIN;
        }

        #CustomerRegister_lnk {
            color: $C_RED;
            font-weight: 700;
            border-bottom: solid 1px $C_ORANGE;
            display: inline-block;
            text-align: center;
            text-decoration: none;
        }
    }
}

/*利用規約同意*/
.agree-table {
	text-align: center;
}

#panel_cust_bar {
    display: none;
}