/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.content {
    
}
