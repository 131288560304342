/* ========================================

    message
        メッセージのパーツ

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
/* override bootstrap*/
.alert{
	border-radius: 0px;
}

.message_area {
	margin-top: 5px;
	margin-bottom: 5px;
}

.t_message,
.t_message_center {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    color: $C_MAIN;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.4em;

    @include mq-pc() {
        font-size: 1.8rem;
    }
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 4px;
        height: 64%;
        background-color: $C_MAIN;
    }
}

.t_message_center {
    border: none;

    &:before {
        height: 0%;
    }


}

.err_message_txt,
.war_message_txt {
	padding: 5px;
}

.text-danger {
    color: $C_RED;
    
    @include mq-mb() {
        font-size: 100%;

        &_center {
            font-size: 100%;
        }
    }
}


.alert-danger {
    margin-top: 50px;
    color: $ERR_FONT;
    border: 2px dotted $ERR_BORDER;
    background: $ERR_BG;
    text-align: center;
    margin-bottom: 100px;

    &:empty {
        display: none;
    }
}

.alert-warning {
    margin: 10px;
    color: $WAR_FONT;
    border: 2px solid $WAR_BORDER;
    background: $WAR_BG;
    font-size: 1.1em;
    text-align: center;

    &:empty {
        display: none;
    }
}

.alert-success {
    color: $SUC_FONT;
    border: 2px solid $SUC_BORDER;
    background: $SUC_BG;
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 100px;

    &:empty {
        display: none;
    }
}

.alert-success a {
    color: $C_RED;
    text-decoration: underline;
}

//エラーメッセージが空のときにボーダーを出ないようにするには
//display: block;ではなくdisplay: table-cell;にしておく
.err_message_txt{
    display: table-cell;
}