/* ========================================
    
    table
        テーブルのパーツ

    INDEX
        - menu

======================================== */

/* ========================================
    table
======================================== */
/* [一覧]---------- */
.tbllst_panel {
	margin-top: 0;
	margin-bottom: 15px;
}
/*一覧行*/
.tbllst_body {
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid solid solid solid;
	border-color: $C_SUB2;
	border-width: 1px;
	margin-bottom: 15px;
}
/*一覧行左(内容)*/
.tbllst_body_left {
	padding: 0 25px;
}
/*一覧行右(リンク)*/
.tbllst_body_right {
	padding: 0 25px;
}
/*一覧行項目*/
.tbllst_item {}
.tbllst_itemlabel {}
.tbllst_item_num {
	/*text-align: right;*/
	font-size: 1.4rem;
}
/*一覧内ボタン*/
.tbllst_link {
	min-width: 50px;
	text-align: center;
}
.tbllst_link a {
    color: $C_RED;
	border: 1px solid $C_RED;
	text-align: center;
	padding: 5px 10px;
	border-radius: 4px;
    transition: 0.3s;
    
    &:hover {
        background-color: $C_RED;
        color: $C_WHITE;
    }
}
/*一覧 ドロップダウンリスト*/
#year_orderlistPanel {
	margin: 15px 0;
}


/* [その他テーブル]---------- */
/*テーブル全体*/
.table {
    max-width: 100%;
    width: 100%!important;
}

.table_panel {
	margin-top: 15px;
	margin-bottom: 15px;
}

/*テーブル全体 会員*/
.table_cust_panel {}

/*テーブル全体 EC*/
.table_ec_panel {}

/*テーブル全体 FC*/
.table_fc_panel {
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    padding: 35px 0px;
    
    &#panel_fanclub {
        background: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }
}
.table_fc_panel > .table_subbox {
	border: dotted 1px #DDD;
}

/*テーブルタイトル*/
.table_header:not(.tblhead_costomer) {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    color: $C_MAIN;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.4em;

    @include mq-pc() {
        font-size: 1.8rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 4px;
        height: 64%;
        background-color: $C_MAIN;
    }
}

/*テーブルバー*/
.table_bar {
	background-color: transparent;
	font-size: 1.8rem;
	font-weight: 700;
	padding: 10px;
    color: $C_BLACK;
}

/*テーブル内グループ*/
.table_subbox {
	margin-top: 10px;
}

/*テーブル内FCグループ全体*/
.table_fcbox {}

/*テーブル内FCグループFC名*/
.table_fcname {}

/*テーブル内未入会FCグループ全体*/
.table_notjoin_fcbox {}

/*テーブル内未入会FCグループFC名*/
.table_notjoin_fcname {}

/*テーブル内グループタイトル*/
.table_subtitle {
	background-color: $C_MAIN;
    border-radius: 10px 10px 0 0;
}

/*テーブル行*/
.table_body {
    position: relative;

    &::after {
        @include mq-mb {
            content: "";
            width: 93%;
            border-top: dotted 2px $C_ORANGE;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

/*テーブル内備考行*/
.table_note {
	padding: 5px;
}

/*テーブル内支払入力行*/
.table_control_pay {}

.table_collapse {
    background: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
}

/*テーブル内開閉リンク*/
a.table_collapse_link {
    position: relative;
    padding: 10px;
    background-color: $C_MAIN;
    color: $C_SUB2;
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px 10px 0 0;
    
    .glyphicon {
        @include center();
        left: auto;
        right: 5px;
        color: $C_MAIN;
    }
}

.table_control {
    padding: 10px;

    @include mq-pc() {
        width: 75%;
        padding: 10px 60px 10px 0;
    }

    @include mq-mb {
        padding: 4px 25px 18.5px;
    }

    &_paytype {
        @include mq-pc() {
            padding: 10px;
        }

        @include mq-mb() {
            padding: 5px;
        }
    }

    &_paydetail {
        padding-left: 12px;
    }
    &_payparam {
        padding: 3px 0;
    }

    &_pay {
        .table_control_paytype input[type="radio"] {
            & ~ .table_control_paydetail {
                display: none;
            }

            &:checked ~ .table_control_paydetail {
                display: block;
            }
        }
    }
}

.tblbody_custedit,
.tblbody_FcCard,
.tblbody_fccontinue {
    margin-top: 20px;

    &::after {
        display: none;
    }

    .table_control {
        padding: 10px;
    }
}

#MyPage {
    .tblbody_custedit,
    .tblbody_FcCard,
    .tblbody_fccontinue,
    #history_fcpayment {
        margin-top: 20px;

        &::after {
            display: none;
        }

        .table_control {
            padding: 0;
        }
    }
}

/*
.table_control_paytype {
	border-style: solid none none;
	border-color: #DDD;
	border-width: 1px;
}

.table_control_pay > .table_control_paytype:first-child {
	border-style: none;
}
*/

/*テーブル行見出し*/
.table_label {
    vertical-align: top;
    
    @include mq-pc() {
        background-color: transparent;
		font-size: 1.2rem;
        font-weight: bold;
		padding: 12px 40px;
		width: 25%;
    }

    @include mq-mb() {
        padding: 15px 20px 0;
        font-weight: 700;
        font-size: 1.2rem;
    }
}

/*テーブルフッター*/
.table_footer {
	padding: 0;
    border-style: none none none none;
    
    div {
        font-weight: 600;

        &.table_control_num {
            font-size: 24px;
        }
    }
}

/*FC未入会テーブル*/
.table_notjoin_fc_panel {
    .table_subtitle {
        padding: 3px 10px 3px 10px;
    }

    & > .table_subbox {
        border: dotted 1px #DDD;
        margin-bottom: 5px;
    }
}

/*会員自由項目テーブル行*/
.freeitem_box {}

/*会員自由項目テーブル行見出し*/
.freetable_label {}

/*会員自由項目テーブル行項目*/
.freetable_control {}

/*FC自由項目ヘッダー(その他情報)*/
.fc_freeitem_header {}

/*FC自由項目テーブル行*/
.fc_freeitem_box {}

/*FC自由項目テーブル行見出し*/
.fc_freetable_label {}

/*FC自由項目テーブル行項目*/
.fc_freetable_control {}

/*FC自由項目フッター(その他情報編集リンク)*/
.fc_freeitem_footer {}


#panel_change_card,
#panel_change_customer,
#panel_change_custoption,
#panel_change_pass,
.tbllst_panel {
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    padding: 35px 0px;
}

.tblhead_fanclub {
    display: none;
}

.tblbody_email {
    .table_control {
        padding: 10px 50px;

        @include mq-mb {
            padding: 10px 25px;
        }
    }
}

#PassEdit {
    .tblbody_email .table_control {
        padding: 0 60px 0 0;
        @include mq-mb {
            padding: 10px 25px;
        }
    }
}