/* ========================================

    index
        ログインページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.panel > .privacy_header,
.panel > .login_header,
.panel > .sinki_header,
.panel > .sinki_header_fc,
.panel > .guest_header {
	background-color: transparent;
    border-bottom: transparent;
    color: $C_MAIN;
    font-size: 1.2rem;
    font-weight: 700;
    padding-top: 35px;
}

.privacy_table,
.login_table,
.sinki_table,
.guest_table,
.kiyaku_table {
	/*text-align: center;*/
}

.privacy_body,
.kiyaku_body {
	padding: 5px;
	text-align: left;
}

.sinki_header_fc {
	border-top-style: solid;
	border-color: #DDD;
	border-width: 1px;

    @include mq-mb {
        width: 240px;
        margin: 0 auto;
    }
}

.login_button,
.sinki_button,
.guest_button {
    @extend %normal-btn;
    @extend %btn-100;
}

.fc_sinki_button {
    @extend %special-btn;
    @extend %btn-100;
    text-decoration: none;
}

/*
.login_title {
	font-weight: 600;
}
.login_title {
	padding: 0 0 0 10px;
	text-align: left;
}
*/

.login_data {
    text-align: center;
    
    input{
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        padding: 5px;
        border-radius: 5px !important;
        border: 1px solid #AAAAAA;
        max-width: 320px;
    }
}

.fcm_loginlist_text{
	margin: 5px 0 10px 0;
}

#PassforgotLink {
    text-align: center;
    display: block;
    color: #999;

    &:hover {
        color: #999;
    }
}

.login {
    .t_title {
        display: none;
    }

    .fc_register_panel {
        color: $C_GRAY;
        background: rgba(255, 255, 255, 0.4);
    }
}