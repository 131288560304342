/* ========================================
    
    title
        見出しのパーツ

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.title_bar > .t_title {
    position: relative;
    margin-bottom: 52px;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    color: $C_MAIN;
    border: none;
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
}

/*タイトル非表示*/
#step_a01,
#step_a02,
#step_a03,
#step_a04 .t_title {
	display: none;
}

#FcCardDelete {
    .t_message {
        display: none;
    }
}

#fcJoinEntry {
    .table_header.tblhead_fcregpayamount {
        margin-left: 40px;

        @include mq-mb {
            margin-left: 10px;
        }
    }
}