/* ========================================

    help
        ヘルプページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.help_panel {
	margin-top: 5px;
    text-align: left;
    
    .help_list {
        border-bottom-style: double;
        border-color: #DDD;
        border-width: 3px;

        li {
            padding: 3px 0;
        }
    }

    .table {
        max-width: 400px;
    }
}

.help_zip_table {
    .table {
        & > thead > tr > th {
            background-color: #F5F5F5;
        }

        & > tbody > tr > td:first-child {
            background-color: #F5F5F5;
            width: 75px;
        }
    }
}

.js-accordion-body {
    display: none;

    &.is-open {
        display: block;
    }
}

.js-accordion-title {
    cursor: pointer;
}

.accordion {
    &-title {
        border: solid 1px $C_ORANGE;
        background: $C_ORANGE;
        color: $C_WHITE;
        font-weight: 500;
        font-size: 1.7rem;
        padding: 23px 65px 23px 20px;
        position: relative;
        border-radius: 10px 10px 0 0;

        &::before,
        &::after {
            position: absolute;
            content: "";
            display: block;
            transition: all 0.4s;
            background: #fff;
            right: 20px;
            top: 50%;
            width: 18px;
            height: 3px;
            transform: translate(-50%, -50%);
        }

        &::before {
            transform: translate(-50%, -50%) rotate(90deg);
        }

        &.js-open {
            &::before {
                transform: translate(-50%, -50%) rotate(0deg);
            }
        }
    }

    &-body {
        border: solid 1px $C_ORANGE;
        border-top: none;
        padding: 23px 65px 23px 20px;
        font-size: 1.5rem;
        letter-spacing: 1px;
        border-radius: 0 0 10px 10px;
    }
}